import { Component, OnInit } from '@angular/core';
import { NgForm } from '@angular/forms';
import { environment } from 'src/environments/environment';
import { Router, NavigationEnd } from '@angular/router';
import { ThanhVien } from 'src/app/shared/ThanhVien.model';
import { ThanhVienService } from 'src/app/shared/ThanhVien.service';
import { DanhMucThanhVien } from 'src/app/shared/DanhMucThanhVien.model';
import { DanhMucThanhVienService } from 'src/app/shared/DanhMucThanhVien.service';
import { DownloadService } from 'src/app/shared/Download.service';
import { NotificationService } from 'src/app/shared/Notification.service';


@Component({
  selector: 'app-register',
  templateUrl: './register.component.html',
  styleUrls: ['./register.component.css']
})
export class RegisterComponent implements OnInit {

  isShowLoading: boolean = false;
  domainName = environment.DomainDestination;
  constructor(
    public ThanhVienService: ThanhVienService,
    public DanhMucThanhVienService: DanhMucThanhVienService,
    public DownloadService: DownloadService,
    public NotificationService: NotificationService,
  ) {
  }
  ngOnInit() {
    this.DanhMucThanhVienGetToListAsync();
  }
  DanhMucThanhVienGetToListAsync() {
    this.DanhMucThanhVienService.baseParameter.Active = false;
    this.DanhMucThanhVienService.GetByActiveToListAsync().subscribe(
      res => {
        this.DanhMucThanhVienService.list = (res as DanhMucThanhVien[]).sort((a, b) => (a.SortOrder > b.SortOrder ? 1 : -1));
        if (this.DanhMucThanhVienService.list) {
          if (this.DanhMucThanhVienService.list.length > 0) {
            this.ThanhVienService.formData.ParentID = this.DanhMucThanhVienService.list[0].ID;
            console.log(this.ThanhVienService.formData);
          }
        }
      },
      err => {
      }
    );
  }
  onSubmit(form: NgForm) {
    this.isShowLoading = true;
    if (this.ThanhVienService.formData.ParentID == null) {
      if (this.DanhMucThanhVienService.list) {
        if (this.DanhMucThanhVienService.list.length > 0) {
          this.ThanhVienService.formData.ParentID = this.DanhMucThanhVienService.list[0].ID;
        }
      }
    }
    this.ThanhVienService.formData.Active = false;
    this.ThanhVienService.SaveAsync().subscribe(
      res => {
        window.location.href = this.domainName + "DangKyThanhCong";
      },
      err => {
        this.NotificationService.warn(environment.RegisterNotSuccess);
        this.isShowLoading = false;
      }
    );
  }
  onVietNam() {
    this.DownloadService.LanguageID = true;
    this.DownloadService.ChangeLanguage();
  }
  onEnglish() {
    this.DownloadService.LanguageID = false;
    this.DownloadService.ChangeLanguage();
  }
}