<div class="row">
    <div class="col s12">
        <div class="container">
            <div id="login-page" class="row">
                <div class="col s12 m6 l4 z-depth-4 card-panel border-radius-6 login-card bg-opacity-8">
                    <form autocomplete="off" class="login-form" #form="ngForm" (submit)="onSubmit(form)">
                        <div class="row">
                            <div class="input-field col s12" style="text-align: center; padding-top: 5px;">
                                Hệ thống cần được thực hiện trên máy tính để có được sự trải nghiệm tốt nhất
                            </div>
                        </div>
                        <div class="row">
                            <div class="input-field col s12" style="text-align: center; padding-top: 5px;">
                                <img width="100" height="100" src="/assets/image/logo.png"
                                    title="Cổng xác thực để truy cập hệ sinh thái số"
                                    alt="Cổng xác thực để truy cập hệ sinh thái số">
                            </div>
                        </div>
                        <div class="row margin">
                            <div class="input-field col s12">
                                <i class="material-icons prefix pt-2">local_phone</i>
                                <input type="text" placeholder="{{DownloadService.TitleDienThoai}}" name="TaiKhoan"
                                    [(ngModel)]="ThanhVienService.formData.TaiKhoan">
                            </div>
                        </div>
                        <div class="row margin">
                            <div class="input-field col s12">
                                <i class="material-icons prefix pt-2">lock_outline</i>
                                <input placeholder="{{DownloadService.TitleMatKhau}}" name="MatKhau"
                                    [(ngModel)]="ThanhVienService.formData.MatKhau" type="password">
                            </div>
                        </div>
                        <div class="row margin">
                            <div class="input-field col s12">
                                <i class="material-icons prefix pt-2">face</i>
                                <input type="text" placeholder="{{DownloadService.TitleHoTen}}" name="Name"
                                    [(ngModel)]="ThanhVienService.formData.Name">
                            </div>
                        </div>
                        <div class="row margin">
                            <div class="input-field col s12">
                                <i class="material-icons prefix pt-2">home</i>
                                <input type="text" placeholder="Đơn vị công tác" name="Description"
                                    [(ngModel)]="ThanhVienService.formData.Description">
                            </div>
                        </div>
                        <div class="row margin">
                            <div class="input-field col s12">
                                <mat-select
                                    style="padding-top:30px; padding-bottom:10px; border-bottom-color: #000000; border-bottom-width: 1px; border-bottom-style: solid;"
                                    name="ParentID" class="browser-default"
                                    [(ngModel)]="ThanhVienService.formData.ParentID">
                                    <mat-option *ngFor="let item of DanhMucThanhVienService.list" [value]="item.ID">
                                        {{item.Name}}
                                    </mat-option>
                                </mat-select>
                            </div>
                        </div>
                        <div class="row">
                            <div class="input-field col s12">
                                <button type="submit"
                                    [disabled]="!ThanhVienService.formData.TaiKhoan || !ThanhVienService.formData.MatKhau"
                                    class="btn waves-effect waves-light border-round gradient-45deg-purple-deep-orange col s12">{{DownloadService.TitleDangKy}}</button>
                            </div>
                        </div>
                        <div class="row">
                            <div class="input-field col s6 m6 l6">
                                <p class="margin medium-small"><a href="{{domainName}}Login" style="font-size: 20px;"
                                        title="{{DownloadService.TitleDangNhap}}">{{DownloadService.TitleDangNhap}}</a>
                                </p>
                            </div>
                            <div class="input-field col s6 m6 l6">
                                <p class="margin right-align medium-small"><a href="{{domainName}}ForgotPassword"
                                        style="font-size: 20px;"
                                        title="{{DownloadService.TitleQuenMatKhau}}">{{DownloadService.TitleQuenMatKhau}}</a>
                                </p>
                            </div>
                        </div>
                    </form>
                </div>
            </div>
        </div>
    </div>
</div>
<app-loading *ngIf="isShowLoading"></app-loading>