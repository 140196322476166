import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { ThanhVien } from 'src/app/shared/ThanhVien.model';
import { BaseService } from './Base.service';
@Injectable({
    providedIn: 'root'
})
export class ThanhVienService extends BaseService{
    displayColumns: string[] = ['DanhMucThanhVienID', 'TaiKhoan', 'HoTen', 'DienThoai', 'Active', 'Save']; 
    list: ThanhVien[] | undefined;                  
    formData!: ThanhVien;
    formDataLogin!: ThanhVien;
    constructor(public httpClient: HttpClient) {
        super(httpClient);
        this.controller = "ThanhVien";
    }
    
    AuthenticationAsync(formData: ThanhVien) {
        let url = this.aPIURL + this.controller + '/AuthenticationAsync';
        const formUpload: FormData = new FormData();
        formUpload.append('data', JSON.stringify(formData));        
        return this.httpClient.post(url, formUpload);
    }       
}

