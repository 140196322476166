export const environment = {
  production: false,  
  DomainURL: "https://login.zerocarbonrice.com/",
  DomainDestination: "https://login.zerocarbonrice.com/#/",
  CMSDomainURL: "https://cms.zerocarbonrice.com/",
  CMSDomainDestination: "https://cms.zerocarbonrice.com/#/",
  APIURL: "https://api.cms.zerocarbonrice.com/api/v1/",    
  APIRootURL: "https://api.cms.zerocarbonrice.com/",        
  InitializationString: "",  
  InitializationNumber: 0, 
  DonViToChucID: 2, 
  DuKhachID: 3, 
  NguoiDanID: 4, 
  Token: "Token",
  ThanhVienParentID: "ThanhVienParentID",
  ThanhVienID: "ThanhVienID",
  ThanhVienHoTen: "ThanhVienHoTen",
  ThanhVienTaiKhoan: "ThanhVienTaiKhoan",
  ThanhVienFileName: "ThanhVienFileName",
  MembershipID: "MembershipID",  
  LoginSuccess: "Đăng nhập thành công.",
  LoginNotSuccess: "Đăng nhập không thành công.",   
  RegisterSuccess: "Đăng ký thành công.",
  RegisterNotSuccess: "Đăng ký không thành công.",  
  EmailRequired: "Email is required.", 
  EmailExists: "Email đã được đăng ký.", 
  PhoneExists: "Điện thoại đã được đăng ký.", 
  EmailNotExists: "Email chưa đăng ký.", 
  MOMOExists: "MOMO đã được đăng ký.",   
  ForgotPassword: "Mật khẩu mới đã được gửi đến email của bạn.",
  UploadSuccess: "Upload thành công.",
  UploadNotSuccess: "Upload không thành công.",
  SaveSuccess: "Lưu thành công.",
  SaveNotSuccess: "Lưu không thành công.",
  DeleteConfirm: "Bạn muốn xóa dữ liệu này?",
  DeleteSuccess: "Xóa thành công.",
  DeleteNotSuccess: "Xóa không thành công.",  
  UserNameExists: "Tài khoản đã tồn tại.",
  UserNameRequired: "UserName là bắt buộc.",
  UserNameActive: "Tài khoản chưa kích hoạt. Vui lòng đợi xác minh từ quản trị.",
};

