import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { ToChucThanhVien } from 'src/app/shared/ToChucThanhVien.model';
import { BaseService } from './Base.service';
@Injectable({
    providedIn: 'root'
})
export class ToChucThanhVienService extends BaseService {
    list: ToChucThanhVien[] | undefined;
    list000: ToChucThanhVien[] | undefined;
    list001: ToChucThanhVien[] | undefined;
    formData!: ToChucThanhVien;

    
    displayColumns001: string[] = ['STT', 'DanhMucChucDanhID', 'DanhMucChungChiID', 'Name', 'Code', 'NamKinhNghiem', 'Note', 'Save'];
    displayColumns002: string[] = ['STT', 'ParentID', 'DanhMucChucDanhID', 'DanhMucChungChiID', 'NamKinhNghiem', 'Save'];

    constructor(public httpClient: HttpClient) {
        super(httpClient);
        this.controller = "ToChucThanhVien";
    }

    GetByThanhVienIDToListAsync() {
        let url = this.aPIURL + this.controller + '/GetByThanhVienIDToListAsync';
        const formUpload: FormData = new FormData();
        formUpload.append('data', JSON.stringify(this.baseParameter));
        return this.httpClient.post(url, formUpload);
    }    
}

